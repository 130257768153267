/* eslint-disable max-classes-per-file */
class iFrameResizerParentWindow {
	constructor({
		iFrameId,
		postMessageSource = "*",
		updateCallback = undefined,
		enableQueryPassThrough = false,
		enableHeight = true,
		enablePosition = false,
		enableUrlUpdate = false,
	}) {
		this._iFrame = undefined;
		this._iFrameId = iFrameId;
		this._postMessageSource = postMessageSource;
		this._updateCallback = updateCallback;
		this._enableQueryPassThrough = enableQueryPassThrough; // Gets query string from url and append to iframe data-src
		this._enableHeight = enableHeight;
		this._enablePosition = enablePosition;
		this._enableUrlUpdate = enableUrlUpdate;
	}

	init() {
		if (this._iFrameId === undefined) {
			console.error("Cannot initialize iFrameResizer without an iFrameId defined.");
			return;
		}

		if (typeof this._updateCallback !== "function") {
			console.error("Cannot initialize iFrameResizer without a callback defined.");
			return;
		}

		// Initialize and retrieve body height
		this._iFrame = document.getElementById(this._iFrameId);

		const iframeResizeInitData = {
			isIFrameResize: true,
			payload: {
				parentPageReady: true,
				enableHeight: this._enableHeight,
				enablePosition: this._enablePosition,
				enableUrlUpdate: this._enableUrlUpdate,
			},
		};

		if (this._enableQueryPassThrough) {
			this._passThroughUrlIframe();
		}

		this._iFrame.onload = () => {
			this._iFrame.contentWindow.postMessage(iframeResizeInitData, this._postMessageSource);
		};

		const that = this;

		function eventListener(event) {
			// Ensure message event is from allowed embedded iframe (Child)
			if (event.origin === that._postMessageSource) {
				if (typeof event.data === "object" && event.data.isIFrameResize) {
					const receivedPayloadData = event.data.payload;
					that._updateCallback(receivedPayloadData);
				}
			}
		}

		window.addEventListener("message", eventListener, false);

		this.cleanUp = () => {
			window.addEventListener("message", eventListener, false);
		};
	}

	// Pass query parameter from parent window to child window
	_passThroughUrlIframe() {
		const iframeSrc = this._iFrame.dataset.src;

		if (window.location.search.length > 0) {
			let _searchParam = window.location.search;
			const _initialSrcParamPos = iframeSrc.indexOf("?");
			if (_initialSrcParamPos > 0) {
				_searchParam = `&${window.location.search.substring(1)}`;
			}
			this._iFrame.setAttribute("src", iframeSrc + _searchParam);
		} else {
			this._iFrame.setAttribute("src", iframeSrc);
		}
	}
}

class iFrameResizerChildWindow {
	constructor({ postMessageOrigin = "*", initFunction, calculatePositionFunction }) {
		this._initialized = false;
		this._postMessageOrigin = postMessageOrigin;
		this._requestAnimationFrame = {
			ref: undefined,
			lastFrameTime: Date.now(),
		};

		this._initFunction = initFunction;

		this._updateHeight = true; // Bare mimumum - always pass height back
		this._heightData = {
			value: undefined,
			hasChanged: false,
		};

		this._updatePosition = false;
		this._positionData = {
			value: undefined,
			hasChanged: false,
		};
		this._calculatePosition = calculatePositionFunction;

		this._updateUrl = true;
		this.cleanUp = () => {};
	}

	init() {
		// Only add resize listner on first load
		if (!this._initialized) {
			this._initialized = true;

			// Run init function
			if (typeof this._initFunction === "function") {
				this._initFunction();
			}

			// Loop to continuously check height of page
			const checkHeight = () => {
				const newHeight = document.body.getBoundingClientRect().height;

				if (this._heightData.value !== newHeight && newHeight !== 0) {
					this._heightData = {
						value: newHeight,
						hasChanged: true,
					};
					return true;
				}

				return false;
			};

			const checkPosition = () => {
				if (typeof this._calculatePosition !== "function") return false;

				// _caculatePosition should return -1 if element is not in visible
				// a hook for when elements are closed / hidden
				const newPosition = this._calculatePosition();

				// Resets position
				if (newPosition === -1) {
					this._positionData.value = -1;
					return false;
				}

				if (this._positionData.value !== newPosition) {
					this._positionData = {
						value: newPosition,
						hasChanged: true,
					};
					return true;
				}

				return false;
			};

			const throttleAnimation = () => {
				const now = Date.now();
				const elapsed = now - this._requestAnimationFrame.lastFrameTime;

				// If 500ms has elapsed (check height twice a second)
				if (elapsed > 500) {
					this._requestAnimationFrame.lastFrameTime = Date.now();

					if (this._updateHeight) {
						checkHeight();
					} else {
						// Remove overflow-y = "hidden"
						document.body.style.overflowY = "";
					}

					if (this._updatePosition) {
						checkPosition();
					}

					this._postUpdatesToParent();
				}

				if (!this._updateHeight && !this._updatePosition) {
					cancelAnimationFrame(this._requestAnimationFrame.ref);
				} else {
					this._requestAnimationFrame.ref = requestAnimationFrame(throttleAnimation);
				}
			};

			this._requestAnimationFrame.ref = requestAnimationFrame(throttleAnimation);

			window.addEventListener(
				"message",
				event => {
					// Ensure message event is from hosting website (Parent)
					if (event.origin === this._postMessageOrigin) {
						if (typeof event.data === "object" && event.data.isIFrameResize) {
							const receivedPayloadData = event.data.payload;

							if (receivedPayloadData && receivedPayloadData.parentPageReady) {
								// Remove overflow by default
								document.body.style.overflowY = "hidden";

								this._updateConfig(receivedPayloadData);
								this._postUpdatesToParent();
							}
						}
					}
				},
				false
			);

			window.addEventListener("unload", this._postBeforeUnloadToParent);

			window.addEventListener("beforeunload", () => {
				window.removeEventListener("unload", this._postBeforeUnloadToParent);
				this._postBeforeUnloadToParent();
			});
		}

		return this;
	}

	_updateConfig(settings) {
		this._updateHeight = settings.enableHeight;
		this._updatePosition = settings.enablePosition;
		this._updateUrl = settings.enableUrlUpdate;
	}

	// eslint-disable-next-line consistent-return
	_postUpdatesToParent() {
		// Only post back to parent if data has changed
		if (!this._heightData.hasChanged && !this._positionData.hasChanged) {
			return false;
		}

		const iframePostData = {
			isIFrameResize: true,
			payload: {},
		};

		if (this._updateHeight && this._heightData.value !== undefined) {
			iframePostData.payload.height = this._heightData.value;
			this._heightData.hasChanged = false;
		}

		if (this._updatePosition && this._positionData.value !== undefined) {
			iframePostData.payload.position = this._positionData.value;
			this._positionData.hasChanged = false;
		}

		if (this._updateUrl) {
			iframePostData.payload.url = {
				base: window.location.origin + window.location.pathname,
				query: window.location.search.substring(1),
			};
		}

		window.parent.postMessage(iframePostData, this._postMessageOrigin);
	}

	_postBeforeUnloadToParent() {
		const iframePostData = {
			isIFrameResize: true,
			payload: {
				unload: true,
			},
		};

		window.parent.postMessage(iframePostData, this._postMessageOrigin);
	}
}

const iFrameResizer = {
	ParentWindow: iFrameResizerParentWindow,
	ChildWindow: iFrameResizerChildWindow,
};

export default iFrameResizer;
