import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import { Section } from "../../Section";
import iFrameResizer from "./iframe";
import config from "./config";
import { RCL as useTranslation } from "../../RCL";
import * as styles from "./CruiseResults.module.scss";

const CruiseIframe = ({ language }) => {
	const instanceRef = useRef(null);
	const iframeRef = useRef(null);
	const iframeLoader = useRef(null);
	const loaderSVG = useRef(null);

	// Loader animating states
	const [toggle, setToggle] = useState(false);
	const [animateInit, setAnimateInit] = useState(false);
	const [style, animate] = useSpring(() => ({ height: "0px" }), []);

	const createQueryObject = _array => {
		// Convert search query into and object for editing
		const obj = {};
		_array.forEach(queryPair => {
			const keyValuePair = queryPair.split("=");
			// eslint-disable-next-line prefer-destructuring
			obj[keyValuePair[0]] = keyValuePair[1];
		});

		return obj;
	};

	const createQueryString = _obj => {
		// Convert search query objects back to a query string
		const obj = { ..._obj };
		const keys = Object.keys(obj);
		const queryString = keys.map((key, i) => `${key}=${decodeURIComponent(obj[keys[i]])}`);
		return queryString.join("&");
	};

	const handlleIframeSetup = () => {
		const updateUrlConfig = {
			default:
				"https://cruises.selloffvacations.com/cs/forms/CruiseResultPage.aspx?skin=271&did=1&target=_self&lid=",
			loading: "https://cruises.selloffvacations.com/CBE/startingpage.aspx",
			results: "https://cruises.selloffvacations.com/cs/forms/CruiseResultPage.aspx",
			details: "https://cruises.selloffvacations.com/cs/forms/CruiseDetails.aspx",
			category: "https://cruises.selloffvacations.com/CBE/Category.aspx",
			cabin: "https://cruises.selloffvacations.com/CBE/Cabin.aspx",
			login: "https://cruises.selloffvacations.com/CBE/Login.aspx",
			passenger: "https://cruises.selloffvacations.com/CBE/Passenger.aspx",
			profile: "https://cruises.selloffvacations.com/CBE/Customer.aspx",
			payment: "https://cruises.selloffvacations.com/CBE/Payment.aspx",
		};

		// On load - change iframe url to defined view
		const promoResultsData = iframeRef.current.getAttribute("data-promo-results");
		const isPromoResult = promoResultsData !== null;

		let cruisebaseFrameUrl = updateUrlConfig.default;
		let isDefaultPage = true;

		let cruiseQueryString;
		let isInitialPromoResultsPage = true;

		const pageQueryArray = window.location.search.substring(1).split("&");
		const pageQueryString = createQueryObject(pageQueryArray);

		if (isPromoResult) {
			// If page url has the "view" query parameter, use that instead of "promo-results" data attribute
			if (pageQueryString.view !== undefined) {
				cruiseQueryString = pageQueryString;
			} else {
				cruiseQueryString = createQueryObject(promoResultsData);
			}
		} else {
			cruiseQueryString = pageQueryString;
		}

		if (
			cruiseQueryString.view !== undefined &&
			updateUrlConfig[cruiseQueryString.view.toLowerCase()] !== undefined
		) {
			cruisebaseFrameUrl = updateUrlConfig[cruiseQueryString.view.toLowerCase()];
			isDefaultPage = false;
		} else {
			// If falling back to default view, add language to default base url
			cruisebaseFrameUrl += language;
		}

		if (isPromoResult) {
			if (isDefaultPage) {
				cruisebaseFrameUrl += "&collapseSearch";
			} else {
				cruisebaseFrameUrl += "?collapseSearch";
			}

			// If page url doesn't have the "view" query parameter but promo-results does, add query to data-src url
			// otherwise, let iFrameResizer passthrough the params
			if (pageQueryString.view === undefined && cruiseQueryString.view !== undefined) {
				cruisebaseFrameUrl += `${createQueryString(cruiseQueryString)}`;
			}
		}

		iframeRef.current.setAttribute("data-src", cruisebaseFrameUrl);

		const updateCallback = receivedPayloadData => {
			if (iframeRef.current == null) return;

			// eslint-disable-next-line no-unused-vars
			const { height, position, url, unload } = receivedPayloadData;

			if (height !== undefined && typeof height === "number") {
				iframeRef.current.style.height = `${height}px`;
			}

			if (
				position !== undefined &&
				position !== -1 &&
				typeof position === "number" &&
				typeof iframeRef.current.offset === "function"
			) {
				const scrollPosition = iframeRef.current.offset().top + position - 20;
				window.scrollTo(0, scrollPosition);
			}

			if (url !== undefined) {
				if (isPromoResult && isInitialPromoResultsPage) {
					isInitialPromoResultsPage = false;
				} else {
					const currentUrl = window.location.href;
					let newViewParam = null;

					// Create new url and push to history if it is in the config
					const updateUrlConfigKeys = Object.keys(updateUrlConfig);
					for (let i = 0; i < updateUrlConfigKeys.length; i++) {
						const urlConfig = updateUrlConfig[updateUrlConfigKeys[i]];
						if (urlConfig === url.base) {
							newViewParam = updateUrlConfigKeys[i];
							break;
						}
					}

					if (newViewParam) {
						const _newQuery = createQueryObject(url.query.split("&"));

						_newQuery.view = newViewParam;

						if (!_newQuery.target) {
							_newQuery.target = "_self";
						}

						const newQuery = createQueryString(_newQuery);
						const newUrl = `${window.location.origin}${window.location.pathname}?${newQuery}`;

						// Push new page to history if link has changed
						if (currentUrl !== newUrl) {
							isInitialPromoResultsPage = false;

							setAnimateInit(false);
							setTimeout(
								() => {
									setToggle(false);
								},
								animateInit ? 5000 : 3000
							);
							window.history.replaceState(null, null, newUrl);
							window.scrollTo(0, 0);
						}
					}
				}
			}

			if (unload !== undefined && unload) {
				setTimeout(() => {
					window.scrollTo(0, 0);
				}, 1);

				const currentParams = createQueryObject(window.location.search.substring(1).split("&"));
				if (currentParams.view !== "payment") {
					setToggle(true);
					setAnimateInit(false);

					setTimeout(
						() => {
							setToggle(false);
						},
						animateInit ? 5000 : 3000
					);
				}
			}
		};

		instanceRef.current = new iFrameResizer.ParentWindow({
			iFrameId: "cruise-results",
			postMessageSource: config.iframeResizer.cruiseResultOrigin,
			updateCallback,
			enableQueryPassThrough: true,
			enableUrlUpdate: true,
			enablePosition: true,
		}).init();
	};

	useEffect(() => {
		if (loaderSVG.current !== undefined) {
			animate({
				height: `${toggle || animateInit ? loaderSVG.current.offsetHeight : 0}px`,
			});
		}
	}, [animate, loaderSVG, toggle, animateInit]);

	useEffect(() => {
		if (iframeLoader.current !== undefined) {
			setAnimateInit(true);
			handlleIframeSetup();
		}

		return () => {
			instanceRef.current?.cleanUp();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Section theme="none" padding="none">
			<section className="cruise-results">
				<animated.div className={styles.loader} ref={iframeLoader} style={style}>
					<object
						ref={loaderSVG}
						data={useTranslation({ searchKey: "cruise-loader" })}
						type="image/svg+xml"
						className={styles.loaderCruise}
					>
						<img src={useTranslation({ searchKey: "cruise-loader" })} alt="Loading" />
					</object>
				</animated.div>
				<iframe
					ref={iframeRef}
					title="Cruise search results"
					id="cruise-results"
					frameBorder="0"
					src="https://cruises.selloffvacations.com/cs/forms/CruiseResultPage.aspx?skin=271&amp;did=1&amp;template=14"
					style={{ height: "1px", width: "100%", maxWidth: "100%", minWidth: "100%" }}
				/>
			</section>
		</Section>
	);
};

CruiseIframe.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
};

export default CruiseIframe;
export { CruiseIframe };
